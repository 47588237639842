import React from 'react';

import { Pane, Heading } from 'evergreen-ui'
import LogoImage from '../../../resources/logo.png';

import PackageJSON from '../../../../package.json';

export default function Logo() {
    return (<Pane display="flex" alignItems="center">
        <Pane marginRight={10}>
            <img src={LogoImage} height={40} alt="App Logo"/>
        </Pane>
        <Heading size={500}>
            Kritware Orchestrator v{PackageJSON.version} - {process.env.REACT_APP_MERCHANT}
        </Heading>
    </Pane>)
}