import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Button, Pane, Heading, Checkbox, TextInputField } from 'evergreen-ui';

import KSingleFileDrop from '../../common/FormComponents/KSingleFileDrop';


export default class CardForm extends Component {
    render() {
        return (<>
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    this.props.onSave(values);
                }}
            >
                {({ errors, handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>

                        <Pane>
                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                <Heading size={500} marginBottom={16}>
                                    Seteaza pretul si imaginea
                                </Heading>

                                <Checkbox
                                    name="priceLocked"
                                    label="Pretul nu se actualizeaza automat"
                                    checked={values.priceLocked || false}
                                    onChange={handleChange} />

                                <TextInputField
                                    required
                                    label="Pret (RON)"
                                    name="price"
                                    type="number"
                                    description="Pretul de vanzare al cartii"
                                    value={values.price || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.price} />

                                <TextInputField
                                    required
                                    label="Cantitate Maxima"
                                    name="maxQuantity"
                                    type="number"
                                    description="Cantitatea maxima disponibila pe site"
                                    value={values.maxQuantity || 0}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.maxQuantity} />

                                <Checkbox
                                    name="active"
                                    label="Disponibil in general"
                                    checked={values.active || false}
                                    onChange={handleChange} />

                                <Checkbox
                                    name="activeOnline"
                                    label="Disponibil pe site"
                                    checked={values.activeOnline || false}
                                    onChange={handleChange} />

                                <Checkbox
                                    name="activeThirdParty"
                                    label="Disponibil pe platforme partenere (ex. CardMarket)"
                                    checked={values.activeThirdParty || false}
                                    onChange={handleChange} />

                            </Pane>

                            <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                <Heading size={500}>Imagine</Heading>
                            </Pane>

                            <Field component={KSingleFileDrop} single={true} name="image" />

                        </Pane>

                        <Button type="submit" onClick={handleSubmit}>
                            Salveaza
                        </Button>

                    </form>
                }}
            </Formik>
        </>
        );
    }
}