import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getProducts, activateProduct, deactivateProduct } from '../actions/ProductActions';
import { getCategories } from '../../categories/actions/CategoryActions';
import { Link } from 'react-router-dom';
import { Button, Pane, SelectField, Heading, TextInput } from 'evergreen-ui';
import MaintenanceModal from '../components/MaintenanceModal';
import ProductCard from '../components/ProductCard';

let searchTimeout = null;

class ListProducts extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            maintenanceModal: false,
            page: 0,
            pages: 0,
            count: 0,
            search: '',
            sort: 'id',
            direction: 'desc'
        }

        this.showMaintenanceModal = this.showMaintenanceModal.bind(this);
        this.hideMaintenanceModal = this.hideMaintenanceModal.bind(this);
        this.setPage = this.setPage.bind(this);

        this.activateProduct = this.activateProduct.bind(this);
        this.deactivateProduct = this.deactivateProduct.bind(this);

        this.productsPerPage = 30;
    }

    setPage(page) {
        this.setState({
            page
        }, () => {
            this.handleTableChange({
                page
            });
        });
    }

    componentDidMount() {
        this.handleTableChange({});
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.products.count !== this.state.count) {
            this.setState({
                count: this.props.products.count,
                pages: Math.ceil(this.props.products.count / this.productsPerPage)
            })
        }
    }

    handleTableChange = (opts) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            opts.start = opts.start || (this.state.page) * this.productsPerPage;
            opts.limit = opts.limit || this.productsPerPage;
            opts.sort = opts.sort || this.state.sort;
            opts.direction = opts.direction || this.state.direction;
            opts.search = opts.search || this.state.search;
    
            this.setState({
                sort: opts.sort || 'id',
                direction: opts.direction || 'id',
                //search: opts.search || this.state.search
            });
    
            if(typeof opts.categoryId !== "string") {
                opts.categoryId = localStorage.getItem('product-listing-category')
            }
    
            if(opts.search) {
                localStorage.setItem('product-listing-search', opts.search);
            }
    
            if(typeof opts.categoryId === "string") {
                localStorage.setItem('product-listing-category', opts.categoryId);
            }
    
            if(opts.page) {
                localStorage.setItem('product-listing-page', opts.page);
            }
    
            this.props.dispatch(getProducts(opts, {categoryId: opts.categoryId}));
            this.props.dispatch(getCategories());
        }, 400)

    }

    activateProduct(productId) {
        this.props.dispatch(activateProduct(productId));
    }

    deactivateProduct(productId) {
        this.props.dispatch(deactivateProduct(productId));
    }

    showMaintenanceModal() {
        this.setState({
            maintenanceModal: true
        })
    }

    hideMaintenanceModal() {
        this.setState({
            maintenanceModal: false
        })
    }


    render() {
        let category = localStorage.getItem('product-listing-category');

        return (<>
            <MaintenanceModal 
                open={this.state.maintenanceModal} 
                onClose={this.hideMaintenanceModal}    
            />

            <Pane borderBottom="2px solid #eeeeee" marginBottom={10}>
                <Heading size={800}>Management Produse ({this.state.count} produse gasite)</Heading>
            </Pane>
                              
            <Pane>
                <Link to="/products/create">
                    <Button iconBefore="add" intent="none" appearance="primary">
                        Produs nou
                    </Button>
                </Link>

                <Button iconBefore="build" type="button" margin={5} onClick={this.showMaintenanceModal} intent="warning" appearance="secondary">
                    Mentenanta Produse
                </Button>
            </Pane>
            <Pane display="flex" marginBottom={16}>
                <TextInput
                    marginTop={5}
                    marginRight={10}
                    placeholder="Cautare"
                    value={this.state.search}
                    onChange={(e) => {
                            this.setState({search: e.target.value}, () => {
                            this.handleTableChange({search: this.state.search})
                        })}
                    }
                />

                <SelectField
                            
                        onChange={(e) => {
                            this.handleTableChange({categoryId: e.target.value});
                        }}
                    >
                        <option value="">Toate categoriile</option>
                        {this.props.categories.flatData.map(cat => (
                            <option key={cat.id} value={cat.categoryHierarchyId} selected={cat.categoryHierarchyId === category}>Categorie - {cat.label}</option>
                        ))}                                
                </SelectField>
                <SelectField   
                    marginLeft={10} 
                    onChange={(e) => {
                        this.handleTableChange({sort: e.target.value.split("-")[0], direction: e.target.value.split("-")[1]})
                    }}>
                        <option value="id-asc">Id ASC</option>
                        <option value="id-desc">Id DESC</option>

                        <option value="price-asc">Pret ASC</option>
                        <option value="price-desc">Pret DESC</option>

                        <option value="quantity-asc">Stocuri ASC</option>
                        <option value="quantity-desc">Stocuri DESC</option>

                        <option value="views-desc">Vizualizari DESC</option>

                        <option value="score-desc">Scor DESC</option>

                </SelectField>
            </Pane>
            <Pane display="grid" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
                {this.props.products.data.map(product => <ProductCard toggleProduct={product.activeOnline ? () => this.deactivateProduct(product.id) : () =>  this.activateProduct(product.id)}product={product} />)}
            </Pane>
            <Pane display="flex" justifyContent="center" flexWrap="wrap">
                {[...Array(this.state.pages).keys()].map(page => <Button onClick={() => this.setPage(page)} appearance={page === this.state.page ? "primary": "default"} marginRight={10} marginBottom={10}>{page + 1}</Button>)}
            </Pane>
            {/* <PagedGrid
                updateKey={this.props.products ? this.props.products.updated : '0'}
                defaultFilter='id'
                defaultDirection='desc'
                defaultSearch={search || ''}
                columns={[
                    {id: 'id', label: 'Id', width: '80px'},
                    {id: 'notifications', label: '', width: '80px', decorator: product => (
                        product.notifications.length > 0 ?   <WarningSignIcon color="warning" marginRight={16}/>
                        : ''
                    )},
                    {id: 'sku', label: 'SKU'},
                    {id: 'image', label: 'Imagine', decorator: product => (
                        <img style={{height: '48px', width: 'auto'}}
                            src={product.images[0] ? 
                                process.env.REACT_APP_IMAGE_STORAGE + '/thumb_' + product.images[0].image + '.webp' : null}/>)},
                    {id: 'categorie', label: 'Categorie', decorator: product => (
                        <Pane>{product.category.label}</Pane>
                    )},
                    {id: 'title', label: 'Nume'},
                    {id: 'quantity', label: 'Cantitate'},
                    {id: 'price', label: 'Pret', decorator: product => product.price + " RON"},
                ]}

                data={this.props.products.data}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/products/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/products/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            /> */}
        </>)
    }
}

function mapStateToProps(state) {
    return {products: state.products, categories: state.categories};
}

export default connect(mapStateToProps)(ListProducts);