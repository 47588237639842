import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrders } from '../actions/OrderActions';
import DataTable from '../../common/DataTable/DataTable';
import { Link } from 'react-router-dom';
import { Badge, Button, Pane } from 'evergreen-ui';
import { AlertBlock, PaymentBlock, PreorderBlock, SinglesBlock, StatusBlock, TypeBlock } from '../components/ComponentBlocks';
import Request from '../../../util/Api';
import IntervalModal from '../components/IntervalModal';
import { StorePickupBlock } from '../components/ComponentBlocks';

class ListOrders extends Component {
    constructor(props) {
        super(props);

        this.openIntervalModal = this.openIntervalModal.bind(this);
        this.closeIntervalModal = this.closeIntervalModal.bind(this);

        this.state = {
            activeOrders: 0,
            intervalModalOpen: false
        }
    }

    openIntervalModal() {
        this.setState({
            intervalModalOpen: true
        })
    }

    closeIntervalModal() {
        this.setState({
            intervalModalClosed: true
        })
    }


    handleTableChange = (opts) => {
        this.props.dispatch(getOrders(opts));

    }

    render() {
        return (<>
            <IntervalModal open={this.state.intervalModalOpen} onClose={this.closeIntervalModal} />

            <Pane display="flex" marginBottom={16}>
                <Pane display="flex" justifyContent="space-between" width="100%">
                    <Pane display="flex">
                        <Link to="/orders/create">
                            <Button iconBefore="add" intent="none" appearance="primary">
                                Comanda noua
                            </Button>
                        </Link>
                    </Pane>

                    <Pane display="flex">
                        <Button intent="none" onClick={this.openIntervalModal}>
                            Export Facturi
                        </Button>

                        {this.state.activeOrders > 0 ?
                            <Button iconBefore="truck" intent="warning" appearance="primary" style={{ marginLeft: '10px' }} onClick={() => { this.sendShipperOrders() }}>
                                Trimite Comanda Curier ({this.state.activeOrders} Colete)
                            </Button> : null}
                    </Pane>

                </Pane>
            </Pane>

            <DataTable
                defaultFilter='orderNumber'
                defaultDirection='desc'
                columns={[
                    { id: 'id', label: 'Id', show: false },
                    { id: 'orderNumber', label: 'Nr. Comanda' },
                    {
                        id: 'isPreorder', label: 'Tags', decorator: (row) => <>
                            <PreorderBlock {...row} />
                            <SinglesBlock {...row}/>
                            <AlertBlock {...row}/>
                            <StorePickupBlock {...row}/>

                        </>, options: [
                            { label: 'Tags', value: 1 },
                        ]
                    },
                    {
                        id: 'orderType', label: 'Tip Comanda', decorator: (row) => <TypeBlock {...row} />, options: [
                            { label: 'Toate', value: '' },
                            { label: 'Facebook', value: 'facebook' },
                            { label: 'Glovo', value: 'glovo' },
                            { label: 'Online', value: 'online' },
                            { label: 'Fizic', value: 'physical' },
                            { label: 'Telefonic', value: 'phone' },
                            { label: 'Email', value: 'email' },
                        ]
                    },
                    {
                        id: 'statusText', filterId: 'status', label: 'Status', decorator: (row) => <StatusBlock {...row} />, options: [
                            { label: 'Toate', value: '' },
                            { label: 'Plasata', value: 'placed' },
                            { label: 'Confirmata', value: 'confirmed' },
                            { label: 'Procesata', value: 'processed' },
                            { label: 'Livrata', value: 'delivered' },
                            { label: 'Finalizata', value: 'completed' },
                            { label: 'Anulata', value: 'cancelled' },
                            { label: 'Returnata', value: 'returned' }
                        ]
                    },
                    {
                        id: 'paymentStatusText', filterId: 'paymentStatus', label: 'Plata', decorator: (row) => <PaymentBlock {...row} />, options: [
                            { label: 'Neplatita', value: 'unpaid' },
                            { label: 'Plata Partiala', value: 'partial_payment' },
                            { label: 'Platita', value: 'paid' },
                            { label: 'Plata Esuata', value: 'payment_failed' },
                            { label: 'Returnata', value: 'refunded' }
                        ]
                    },
                    { id: 'billingName', label: 'Nume' },
                    { id: 'emailOrderCount', label: 'Nr. Comenzi'},
                    { id: 'email', label: 'Email'},
                    { id: 'grandTotal', label: 'Valoare', decorator: (row) => (row.grandTotal > 0 ? `${row.grandTotal} RON` : '-') },
                    { id: 'createdAt', label: 'Creat', decorator: 'datetime' },
                    { id: 'updatedAt', label: 'Updated', decorator: 'datetime' }
                ]}

                data={this.props.data}
                onTableChange={this.handleTableChange}

                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}

                itemMenu={[
                    { getLink: (item) => `/orders/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/orders/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.orders;
}

export default connect(mapStateToProps)(ListOrders);
