import React from 'react';
import { useState } from 'react';
import moment from 'moment';

import Request from '../../../util/Api';

import OrderStatusProcessedModal from './OrderStatusProcessedModal';
import OrderStatusReturnedModal from './OrderStatusReturnedModal';

import { Pane, Heading, Button, Icon, Text, TimeIcon, SavedIcon, TruckIcon, TickCircleIcon, ErrorIcon, ShopIcon, TickIcon, CrossIcon, DownloadIcon, ConfirmIcon } from 'evergreen-ui';

export default function OrderControl(props) {
    const [processOrderModal, setProcessOrderModal] = useState(false);
    const [returnOrderModal, setReturnOrderModal] = useState(false);

    const handleInvoiceDownload = async (invoice) => {
        const data = await Request.get('/orders/' + props.order.id + '/invoice/' + invoice.id);
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = invoice.series+invoice.number+'.pdf';
        document.body.appendChild(a);
        a.click();
    }

    const handleInvoiceRegen = async (invoice) => {
        const data = await Request.get('/orders/' + props.order.id + '/invoice/' + invoice.id + '/regenerate');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();

        window.location.reload();
    }


    const handleNewInvoiceDownload = async () => {
        const data = await Request.get('/orders/' + props.order.id + '/invoice/');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    const handleAwbDownload = async (format = 'A4') => {
        const data = await Request.get('/orders/' + props.order.id + '/awb?format=' + format);
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    const handleQRCodeDownload = async () => {
        const data = await Request.get('/orders/' + props.order.id + '/qr');
        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    const resendPaymentEmail = async () => {
        await Request.post('/orders/' + props.order.id + '/resend-payment-link');
    }

    const openProcessOrderModal = (status) => {
        // if (props.order.status === status) {
        //     return;
        // }
        setProcessOrderModal(true);
    }

    const closeProcessOrderModal = () => {
        setProcessOrderModal(false);
    }

    const openReturnOrderModal = (status) => {
        setReturnOrderModal(true);
    }

    const closeReturnOrderModal = () => {
        setReturnOrderModal(false);
    }

    return <>
        <OrderStatusProcessedModal open={processOrderModal}
            onClose={closeProcessOrderModal}
            confirmOperation={(values) => props.updateOrderStatus('processed', values)}
            order={props.order}

            //Todo replace hardcoded fields with actual automated assessments
            checks={{
                stock: true,
                emailNotifications: true,
                smsNotifications: true
            }}
        />

        <OrderStatusReturnedModal open={returnOrderModal}
            onClose={closeReturnOrderModal}
            confirmOperation={(values) => props.updateOrderStatus('returned', values)}
            order={props.order}
        />

        <Pane display="flex" flexDirection="column">

            <Button type="button"
                marginBottom={5}
                intent="danger"
                appearance={props.order.status === 'placed' ? 'primary' : 'minimal'}
                disabled={props.order.status !== 'placed'}
                onClick={() => props.updateOrderStatus('placed')}
            >
                <Icon icon={TimeIcon} marginRight={5} />
                Plasata
            </Button>
            {props.order.orderType !== 'glovo' ? <>
                <Button type="button"
                    marginBottom={5}
                    intent="info"
                    appearance={props.order.status === 'confirmed' ? 'primary' : 'minimal'}
                    disabled={['placed', 'confirmed', 'cancelled'].indexOf(props.order.status) === -1}
                    onClick={() => props.updateOrderStatus('confirmed')}
                >
                    <Icon icon={TimeIcon} marginRight={5} />
                    Confirmata
                </Button>

                <Button type="button"
                    marginBottom={5}
                    intent="warning"
                    appearance={props.order.status === 'processed' ? 'primary' : 'minimal'}
                    disabled={['confirmed', 'processed'].indexOf(props.order.status) === -1}
                    onClick={() => openProcessOrderModal('processed')}
                >
                    <Icon icon={SavedIcon} marginRight={5} />
                    Procesata
                </Button>
                {props.order.delivery === "delivery" ?
                    <Button
                        type="button"
                        marginBottom={5}
                        intent="default"
                        appearance={props.order.status === 'delivered' ? 'primary' : 'minimal'}
                        disabled={['processed', 'delivered'].indexOf(props.order.status) === -1}
                        onClick={() => props.updateOrderStatus('delivered')}
                    >
                        <Icon icon={TruckIcon} marginRight={5} />
                        Livrata
                    </Button> : null}
                {props.order.delivery === "delivery" && props.order.status === "delivered" ?
                    <Text>
                        Status: {props.order.deliveryStatusText}
                    </Text> : null}
            </> : null}
            <Button
                type="button"
                marginBottom={5}
                intent="success"
                appearance={props.order.status === 'completed' ? 'primary' : 'minimal'}
                disabled={['confirmed', 'processed', 'delivered', 'completed'].indexOf(props.order.status) === -1}
                onClick={() => props.updateOrderStatus('completed')}
            >
                <Icon icon={TickCircleIcon} marginRight={5} />
                Finalizata
            </Button>
            <Button
                type="button"
                marginBottom={5}
                appearance={props.order.status === 'cancelled' ? 'primary' : 'minimal'}
                disabled={['placed', 'confirmed', 'processed', 'delivered', 'completed', 'cancelled'].indexOf(props.order.status) === -1}
                onClick={() => props.updateOrderStatus('cancelled')}
            >
                <Icon icon={ErrorIcon} marginRight={5} />
                Anulata
            </Button>
            <Button
                type="button"
                marginBottom={5}
                appearance={props.order.status === 'returned' ? 'primary' : 'minimal'}
                disabled={['delivered', 'completed', 'returned'].indexOf(props.order.status) === -1}
                onClick={() => openReturnOrderModal('returned')}
            >
                <Icon icon={ShopIcon} marginRight={5} />
                Returnata
            </Button>
        </Pane>

        <Heading size={500} marginTop={15}>Plata ({props.order.paymentType ? props.order.paymentType.name : '...'})</Heading>
        <Pane display="flex" flexDirection="column">
            {props.order.bankPaymentReference ?
                <Text>Referinta Transfer: <strong>{props.order.bankPaymentReference}</strong></Text> : null}
            <Text color={props.order.paymentStatus === 'unpaid' ? "danger" : "success"}>Status: {props.order.paymentStatusText}</Text>
            {props.order.paymentStatus === 'unpaid' && ['returned', 'cancelled'].indexOf(props.order.status) === -1 ?
                <Button type="button" intent="success" onClick={() => props.confirmOrderPayment('confirmed')}>
                    <Icon icon={TickIcon} />
                    Confirma Plata
                </Button> : null}
            {props.order.paymentStatus !== 'refunded' && (props.order.status === 'returned' || props.order.status === 'cancelled') ?
                <Button type="button" intent="danger" onClick={() => props.confirmOrderPayment('confirmed')}>
                    <Icon icon={CrossIcon} />
                    Confirma Returnare Plata
                </Button>
                : null}

            {props.order.paymentStatus === 'unpaid' && props.order.paymentType.handler === 'card-gateway' ?
                <Button type="button" intent="danger" onClick={() => resendPaymentEmail()}>
                    Retrimite Link Plata Card
                </Button>
                : null
            }

        </Pane>

        <Heading size={500} marginTop={15}>Documente</Heading>
        <Pane display="flex" flexDirection="column">
            <Button type="button" onClick={() => handleQRCodeDownload()} marginBottom={5}>
                <Icon icon={DownloadIcon} marginRight={5} />
                COD QR
            </Button>
            {props.order.documentId ?
                <Text>Bon fiscal {props.order.documentId}/{props.order.createdAt.format('DD.MM.YYYY')}</Text> : null}
            {(!props.order.invoices || props.order.invoices.length === 0) && props.order.billingAddress && props.order.billingName ?
                <Button type="button" onClick={() => handleNewInvoiceDownload()} marginBottom={5}>
                    <Icon icon={DownloadIcon} marginRight={5} />
                    Factureaza Comanda
                </Button>
                : null}
            {(props.order.invoices || []).map(invoice => (<>
                        <Button type="button" onClick={() => handleInvoiceDownload(invoice)} marginBottom={5}>
                            <Icon icon={DownloadIcon} marginRight={5}/>
                            Download
                            {invoice.type === 'invoice' ? ' Factura' : ' Proforma'} {invoice.number}/{moment(invoice.date).format('YYYY-MM-DD')}
                            {invoice.cancels ? ' (STORNO)' : ''}
                        </Button>

                        <Button type="button" onClick={() => handleInvoiceRegen(invoice)} marginBottom={5}>
                            <Icon icon={ConfirmIcon} marginRight={5}/>
                            Regenereaza
                            {invoice.type === 'invoice' ? ' Factura' : ' Proforma'} {invoice.number}/{moment(invoice.date).format('YYYY-MM-DD')}
                            {invoice.cancels ? ' (STORNO)' : ''}
                        </Button>
                </>
            ))}

            {props.order.deliveryReference ? <>
                <Button type="button" onClick={() => handleAwbDownload('A4')}>
                    <Icon icon={DownloadIcon} marginRight={5} />
                    Download AWB (A4)
                </Button>
                <Button type="button" onClick={() => handleAwbDownload('A6')}>
                    <Icon icon={DownloadIcon} marginRight={5} />
                    Download AWB (A6)
                </Button>


            </> : null}
        </Pane>
    </>
}
