import { useState } from 'react';
import { Button, Heading, Pane, Text } from 'evergreen-ui';
import moment from 'moment';
import React from 'react';
import Request from '../../../util/Api';
import Config from '../Config';


import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function StockAccountInfoBlock(props) {
    const [loading, setLoading] = useState(false);

    const resync = async () => {
        setLoading(true);
        await Request.post(Config.Endpoints.resyncExpansion + '?expansionId=' + props.cardExpansion.id);
        setLoading(false);
    }

    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Cont"
        button={{
            link:`/card-expansions/${props.cardExpansion.id}/edit`,
            label: 'Modifica'
        }}    
    >
        <InfoLine label="Id Intern" value={props.cardExpansion.id}/>
        <InfoLine label="Cod" value={props.cardExpansion.code}/>
        <InfoLine label="Nume" value={props.cardExpansion.name}/>
        <InfoLine label="Lansare" value={moment(props.cardExpansion.releaseDate).format('DD/MM/YYYY')}/>
        <Pane display="flex">
            <InfoLine label="Sincronizat" value={props.cardExpansion.cardsSynced} type="bool"/>
            {loading ? <Text>Se sincronizeaza...</Text> : 
                <Button onClick={resync}>Resincronizeaza</Button>}
        </Pane>

                      
        <InfoLine label="Disponibil" value={props.cardExpansion.active} type="bool" />
        <InfoLine label="Disponibil Online" value={props.cardExpansion.activeOnline} type="bool" />
        <InfoLine label="Disponibil Third Party" value={props.cardExpansion.activeThirdParty} type="bool" />


        <Heading marginTop={20} marginBottom={10}>Preturi Minime</Heading>
        <InfoLine label="Common" value={props.cardExpansion.customData?.common}/>
        <InfoLine label="Rare" value={props.cardExpansion.customData?.rare}/>
        <InfoLine label="Super Rare" value={props.cardExpansion.customData?.superRare}/>
        <InfoLine label="Ultra Rare" value={props.cardExpansion.customData?.ultraRare}/>
        <InfoLine label="Secret Rare" value={props.cardExpansion.customData?.secretRare}/>
        <InfoLine label="Ultimate Rare" value={props.cardExpansion.customData?.ultimateRare}/>
        <InfoLine label="Ghost Rare" value={props.cardExpansion.customData?.ghostRare}/>
        <InfoLine label="Token" value={props.cardExpansion.customData?.token}/>
        <InfoLine label="Gold Rare" value={props.cardExpansion.customData?.goldRare}/>
        <InfoLine label="Gold Secret Rare" value={props.cardExpansion.customData?.goldSecretRare}/>
        <InfoLine label="Mosaic Rare" value={props.cardExpansion.customData?.mosaicRare}/>
        <InfoLine label="Oversized" value={props.cardExpansion.customData?.oversized}/>
        <InfoLine label="Platinum Rare" value={props.cardExpansion.customData?.platinumRare}/>
        <InfoLine label="Shatterfoil" value={props.cardExpansion.customData?.shatterfoil}/>
        <InfoLine label="Starfoil Rare" value={props.cardExpansion.customData?.starfoilRare}/>
        <InfoLine label="Special" value={props.cardExpansion.customData?.special}/>


    </InfoBlock>
}