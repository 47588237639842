import { setLoading, unsetLoading } from '../../common/Loading/Actions';
import { toaster } from 'evergreen-ui';

import ModuleConfig from '../Config';
import Request from '../../../util/Api';
import ErrorHandler from '../../../util/ErrorHandler';

const actions = ModuleConfig.Actions;
const endpoints = ModuleConfig.Endpoints;
const InventoryBuffer = ModuleConfig.Structures.InventoryBuffer;
const CRUDActions = ModuleConfig.CRUDActions;


export function getInventoryBuffers(opts = { start: 0, limit: 0}) {
    return CRUDActions.getAll(actions.getAll, {
        ...opts,
        endpoint: endpoints.inventoryBuffers,
        mapStructure: InventoryBuffer,
        errorNotification: "Inventory Buffers nu au putut fi incarcate"
    });
}

export function getInventoryBuffer(id) {
    return CRUDActions.getOne(actions.getOne, {
        id,
        endpoint: endpoints.inventoryBuffer,
        mapStructure: InventoryBuffer,
        errorNotification: "Inventory Buffer nu a putut fi incarcata"
    })
}

export function updateInventoryQuantity(itemId, type, quantity) {
    console.log(itemId, quantity);

    return async (dispatch) => {
        dispatch(setLoading(actions.edit.request.id));
        dispatch(actions.getOne.request.thunk());

        try {
            const result = await Request.put(endpoints.inventoryBuffer, { id: itemId }, null, { quantity, type });
            toaster.success('Cantitatea a fost modificata');

        } catch (e) {
            toaster.danger(e.message || 'Cantitatea nu a putut fi modificata (eroare)');
            const parsedErrors = ErrorHandler.parse(e);
            dispatch(actions.edit.failure.thunk(parsedErrors));
        }
        
        // try {
        //     const result = await Request.put(opts.endpoint, { id: opts.id }, null, data);
        //     const updateData = new opts.mapStructure(result.data);

        //     dispatch(actionGroup.success.thunk(updateData));

        //     if(opts.successNotification) {
        //         toaster.success(opts.successNotification);
        //     }
            
        //     if(opts.nextUrl) {
        //         dispatch(push(opts.nextUrl + updateData.id));
        //     }

        // } catch (e) {
        //     const parsedErrors = ErrorHandler.parse(e);

        //     if(opts.errorNotification) {
        //         toaster.danger(opts.errorNotification);
        //     }

        //     dispatch(actionGroup.failure.thunk(parsedErrors));
        // }

        dispatch(unsetLoading(actions.edit.request.id));
    }
}

// export function createInventoryBuffer(values) {
//     return CRUDActions.create(actions.create, {
//         values,
//         endpoint: endpoints.inventoryBuffers,
//         mapStructure: InventoryBuffer,
//         successNotification: "Inventory Buffer a fost creata",
//         nextUrl: '/inventory-buffer/',
//     })
// }

// export function updateInventoryBuffer(id, values) {
//     return CRUDActions.update(actions.edit, {
//         id,
//         values,
//         endpoint: endpoints.inventoryBuffer,
//         mapStructure: InventoryBuffer,
//         successNotification: "Inventory Buffer a fost modificata",
//         nextUrl: '/inventory-buffer/',
//     })
// }

// export function deleteInventoryBuffer(id) {
//     return CRUDActions.remove(actions.delete, {
//         id,
//         endpoint: endpoints.inventoryBuffer,
//         successNotification: "Inventory Buffer a fost stearsa",
//         nextUrl: '/inventory-buffer'
//     });
// }
