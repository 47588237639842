export default class Card {
    constructor(props) {
        this.id = props.id || 0;
        this.cardMarketId = props.cardMarketId;
        this.expansionId = props.expansionId;
        this.name = props.name;
        this.lang = props.lang;
        this.number = props.number;
        this.code = props.code;
        this.rarity = props.rarity;
        this.thumbnail = props.thumbnail;
        this.image = props.image;
        this.quantity = props.quantity;
        this.maxQuantity = props.maxQuantity;
        this.price = props.price;
        this.priceLocked = props.priceLocked ? true : false

        this.priceTable = props.priceTable;
        this.variants = props.variants || [];

        this.views = props.views;

        this.active = props.active;
        this.activeOnline = props.activeOnline;
        this.activeThirdParty = props.activeThirdParty;
        this.variantName = props.variantName;

        this.cardData = {};
        
        try {
            this.cardData = JSON.parse(JSON.parse(props.cardData));
        } catch (e) {

        }
    }

    export() {
        return {
            id: this.id || null,
            image: this.image || null,
            price: this.price || 0,
            priceLocked: this.priceLocked || false,
            maxQuantity: this.maxQuantity || 0,
            active: this.active ? 1 : 0,
            activeOnline: this.activeOnline ? 1 : 0,
            activeThirdParty: this.activeThirdParty ? 1 : 0
        }
    }
}