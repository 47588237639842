export const StockOperationTypes = [
    {
        id: 'NSOR',
        label: 'Com. Furnizor',
        labelEx: 'Comanda Furnizor',
        important: true
    },
    {
        id: 'NSRT',
        label: 'Ret. Furnizor',
        labelEx: 'Retur Comanda Furnizor'
    },
    {
        id: 'NUPR',
        label: 'Op. PF',
        labelEx: 'Operatie Persoana Fizica'
    },
    {
        id: 'NTRA',
        label: 'Op. Stocuri',
        labelEx: 'Operatie Stocuri (Transformare)'
    },
    {
        id: 'NPRE',
        label: 'Precomanda',
        labelEx: 'Precomanda'
    },
    {
        id: 'NOTH',
        label: 'Alta',
        labelEx: 'Alt tip de operatie'
    }
]