import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane, Heading } from 'evergreen-ui';
import KSingleFileDrop from '../../common/FormComponents/KSingleFileDrop';
import CarouselShowcaseForm from './CarouselShowcaseForm';
import CarouselShowcasePreview from './CarouselShowcasePreview';
import { SketchPicker } from 'react-color';

export default class CarouselForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
    
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values, setFieldValue}) => {
                    errors = Object.assign({}, this.props.errors, errors);
                   
                    return <form>
                        <Pane display="flex">
                            <Pane flexGrow={0.7} marginRight={10}>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Date Primare</Heading>
                                </Pane>

                                <SelectField
                                    label="Varianta"
                                    name="variant"
                                    description="Tipul slide-ului"
                                    value={values.variant}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <option key="null" value="">Alege</option>
                                    <option key="showcase" value="showcase">Showcase</option>         
                                </SelectField>

                                <TextInputField
                                    required
                                    label="Nume"
                                    name="name"
                                    description="Numele slide-ului"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.name}/>

                                <TextInputField
                                    required
                                    label="Pozitie"
                                    name="position"
                                    description="Pozitia slide-ului in carusel"
                                    value={values.position || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                validationMessage={errors.position}/>

                                <Checkbox 
                                    name="active" 
                                    label="Activ"
                                    checked={values.active || false}
                                    onChange={handleChange}/>
                            </Pane>

                            <Pane flexGrow={0.3} marginRight={10}>
                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={500}>Imagini si Culori</Heading>
                                </Pane>

                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={300}>Imagine Background</Heading>
                                </Pane>

                                <Field component={KSingleFileDrop} name="backgroundImage" prefix="carousel_bg_default_"/>

                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={300}>Imagine Grafica</Heading>
                                </Pane>

                                <Field component={KSingleFileDrop} name="graphicsImage" prefix="carousel_gfx_default_"/>

                                <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                                    <Heading size={300}>Culoare Background</Heading>
                                </Pane>

                                <SketchPicker 
                                    disableAlpha={true}
                                    color={values.backgroundColor}
                                    onChange={(color, event) => {
                                        setFieldValue('backgroundColor', color.hex);  
                                    }}
                                />
                            </Pane>
                            <Pane>
                                <CarouselShowcasePreview carousel={values}/>
                            </Pane>
                        </Pane>
                                            
                        <Pane borderBottom="default" paddingBottom={8} marginBottom={8}>
                            <Heading size={500}>Proprietati Varianta</Heading>
                        </Pane>
                                                
                        <CarouselShowcaseForm 
                            errors={errors} 
                            handleChange={handleChange} 
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            values={values}
                            setFieldValue={setFieldValue}/>

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}