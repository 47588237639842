import React, { useState } from 'react';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';
import Request from '../../../util/Api';
import { round } from 'lodash';
import { Heading, Text, Pane, Button, Link } from 'evergreen-ui';
import configuration from '../../../util/Configuration';
import KImageComponent from '../../common/FormComponents/KImageComponent';
import LabelModal from './LabelModal';
import StockModal from './StockModal';


export default function ProductInfoBlock(props) {
    const [ showLabelModal, setShowLabelModal ] = useState(false);
    const [ showStockModal, setShowStockModal ] = useState(false);

    const handleInvoiceDownload = async (title = '', totalPrice = '', totalOldPrice = '') => {
        let extras = {};
        if(title) {
            extras = {
                title: typeof title === 'string' ? title : '',
                totalPrice,
                totalOldPrice
            }
        }
        const data = await Request.post('/products/price-labels', null, null, [
            { id: props.product.id, quantity: 1, ...extras }
        ]);

        const file_path = data.data;
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path;
        document.body.appendChild(a);
        a.click();
    }

    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Produs"
        button={{
            link:`/products/${props.product.id}/edit`,
            label: 'Modifica'
        }}    
    >

        <LabelModal open={showLabelModal} 
            onDownload={handleInvoiceDownload}
            onClose={() => setShowLabelModal(false)}/>
        
        <StockModal 
            product={props.product}
            open={showStockModal}
            onClose={() => setShowStockModal(false)}/>

        <Pane>
            <Button type="button" onClick={handleInvoiceDownload} marginRight={10}>
                Download Label Pret
            </Button>
            
            <Button type="button" onClick={() => setShowLabelModal(true)} marginRight={10}>
                Download Label Pret Custom
            </Button>

            <Button type="button" onClick={() => setShowStockModal(true)}>
                Raport Stocuri
            </Button>
        </Pane>
        
        

        <Pane borderBottom="default" marginBottom={16} paddingBottom={16}>
            <InfoLine label="Id Intern" value={props.product.id}/>
            <InfoLine label="Cod Bare" value={props.product.barcode}/>
            <InfoLine label="SKU" value={props.product.sku}/>
            <InfoLine label="Categorie" value={props.product.category.title}/>
            <InfoLine label="Varianta" value = {props.product.variant ? props.product.variant.title + ' - ' + props.product.variant.sku : "NU"}/>
            <InfoLine label="Activ" value={props.product.active} type="bool"/>
            <InfoLine label="Vizualizari" value={props.product.views}/>
            <InfoLine label="Nume" value={props.product.title}/>
            <InfoLine label="Descriere" value={props.product.description}/>
            <InfoLine label="Meta Titlu" value={props.product.metaTitle}/>
            <InfoLine label="Meta Description" value={props.product.metaDescription}/>
            <InfoLine label="SLUG" value={props.product.slug}/>
            <InfoLine label="Pret Initial" value={props.product.sourcePrice}/>
            <InfoLine label="Pret Final (cu TVA)" value={props.product.totalPrice}/>
            <InfoLine label="TVA" value={props.product.vat}/>
            <InfoLine label="Cota TVA" value={props.product.vatBracket}/>
            <InfoLine label="Cantitate" value={props.product.quantity}/>
            <InfoLine label="Canitate Maxima" value={props.product.maxQuantity}/>
            <InfoLine label="Unitate" value={props.product.unit ? props.product.unit.name : "N/A"}/>

            <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
                <Heading size={500}>Imagini</Heading>
            </Pane>

            <Pane display="flex">
                {props.product.images ? props.product.images.map((image, index) => (
                    <KImageComponent isView={true} image={`${configuration.images}/thumb_${image.image}.jpg`} key={`product_image_${index}`}/>
                )) : null }
            </Pane>
            
            <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
                <Heading size={500}>Atribute</Heading>
            </Pane>

            {props.product.attributes ? 
                Object.values(props.product.attributes).map((attribute, index) => (
                <InfoLine label={attribute.name} 
                    value={attribute.value} 
                    key={`product_attribute_${index}`} 
                    type={attribute.type === 'rich-text-area' ? 'rich-text' : ""}/>
            )) : null}

            <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
                <Heading size={500}>Stocuri</Heading>
            </Pane>

            {props.product.stocks ? 
                Object.values(props.product.stocks).map((stock, index) => (
                <InfoLine label={stock.status} 
                    value={(round(stock.entryValue * (100 + props.product.vatBracket) / 100, 2)) + ` (${stock.entryDocument})`} 
                    key={`product_stock_${index}`} />
            )) : null}

            <Pane borderBottom="default" padding={16} marginTop={16} marginBottom={16}>
                <Heading size={500}>Notificari</Heading>
            </Pane>

            {props.product.notifications ? 
                props.product.notifications.map(notification => <Pane>
                    {notification.type === 'IMAGE-ANALYSIS' ? 
                        <Pane>
                            <Text>Analiza Imagini</Text>
                        </Pane>: null}
                    {notification.description === 'NO-IMAGE' ? 
                        <Pane>
                            <Text>Produsul nu are imagini!</Text>
                        </Pane> :
                    notification.description === 'LOW-RESOLUTION' ? 
                        <Pane>
                            <Text>Imaginea sursa are rezolutia prea mica (target: 1920px x 1920px; real: {notification.extraData.width}px x {notification.extraData.height}px ) - </Text>
                            <Link href={`${configuration.images}/original_${notification.extraData.image.image}.jpg`} target="_blank">Vezi Imagine (tab nou)</Link>
                        </Pane> : 
                    notification.description === 'BLURRY' ? 
                        <Pane>
                            <Text>Imaginea sursa pare neclara (scor: {notification.extraData.sharpness}) - </Text>
                            <Link href={`${configuration.images}/original_${notification.extraData.image.image}.jpg`} target="_blank">Vezi Imagine (tab nou)</Link>
                        </Pane> : null
                    }
            </Pane>) : null}
            
        </Pane>        
    </InfoBlock>
}