import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';

import ListProducts from './containers/ListProducts';
import CreateProduct from './containers/CreateProduct';
import EditProduct from './containers/EditProduct';
import ViewProduct from './containers/ViewProduct';

const routes = [
    {
        type: 'crud',
        list: ListProducts,
        create: CreateProduct,
        edit: EditProduct,
        view: ViewProduct
    }
]

export default ModuleBootstrapper.getRoutes("products", routes)