import React from 'react';
import { Route } from 'react-router-dom';

const RouteGenerator = function(spec, routes) {
    return routes.map(route => {
        switch(route.type) {
            case 'simple':
                return [<Route key={`route_${spec}_${route.name}`}
                            exact path={route.path}
                            component={route.view}/>]
            case 'crud':
                return [
                    <Route key={`route_${spec}_list`}
                        exact path = {`/${spec}`}
                        component={route.list}/>,
                    <Route key={`route_${spec}_create`}
                        exact path = {`/${spec}/create`}
                        component={route.create}/>,
                    <Route key={`route_${spec}_edit`}
                        exact path = {`/${spec}/:id/edit`}
                        component={({match}) => <route.edit key={match.params.id} {...match}/>}/>,
                    <Route key={`route_${spec}_view`}
                        exact path = {`/${spec}/:id`}
                        component={({match}) => <route.view key={match.params.id} {...match}/>}/>
                ]
            default:
        }

        return [<Route key={`route_${spec}_${route.name}`}
                            exact path={route.path}
                            component={route.view}/>]
    }).flat(1);
}

export default RouteGenerator;