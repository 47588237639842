import React, { Component } from 'react';
import _ from 'lodash';
import { Formik, FieldArray } from 'formik';
import { TextInputField, Button, SelectField, Checkbox, Pane, Heading, SegmentedControl, Table, Text } from 'evergreen-ui';

import { textValues } from '../../../structures/Order';
import ProductAutocomplete from '../../products/components/ProductAutocomplete';
import OrderAutocomplete from './OrderAutocomplete';
import CardAutocomplete from '../../cards/components/CardAutocomplete';
import LocationAutocomplete from './LocationAutocomplete';

const productPrice = (product, orderType) => {

    textValues.orderType.find(ot => ot.value === orderType);
    let price = product.price;
    return price;
}


export default class OrderForm extends Component {
    render() {
        if (_.isEmpty(this.props.initialValues)) {
            return null;
        }

        if(['placed', 'confirmed', 'processed'].indexOf(this.props.initialValues.status) === -1) {
            return (<>
                    <Heading size={600}>Comanda nu mai poate fi editata dupa ce a fost livrata</Heading>
                    {/* <Text>Daca doresti sa faci vreo modificare, anuleaza comanda si creeaza una noua.</Text> */}
                </>
            )
        }

        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                    this.props.onSave(values);
                }}
            >
                {({ errors, handleChange, handleBlur, handleSubmit, values, setFieldValue }) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <Pane>
                            <TextInputField
                                    required
                                    label="Comentariu"
                                    name="comment"
                                    value={values.comment || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validationMessage={errors.comment} />
                        </Pane>
                        <Pane display="flex">

                            <Pane marginRight={10} >
                                <SelectField
                                    label="Canalul Comenzii"
                                    onChange={(event) => {
                                        if(event.target.value === 'glovo') {
                                            setFieldValue('delivery', 'personal')
                                            setFieldValue('paymentTypeId', (this.props.paymentTypes.find(pt => pt.handler === 'third-party') || { id: 0 }).id);
                                        }

                                        if(event.target.value === 'physical') {
                                            setFieldValue('delivery', 'personal')
                                        }

                                        for(let i in values.products) {
                                            setFieldValue(`products.${i}.price`, productPrice(values.products[i], event.target.value))
                                        }

                                        handleChange(event);
                                    }}
                                    name="orderType">
                                    {textValues.orderType.map(orderType => (
                                        <option value={orderType.value} selected={orderType.value === values.orderType}>{orderType.label}</option>
                                    ))}
                                </SelectField>

                            </Pane>

                            <Pane marginRight={10}>
                                <Heading size={400} marginBottom={4}>Livrare</Heading>
                                <SegmentedControl
                                    marginBottom={16}
                                    width={300}
                                    options={textValues.delivery.filter(val => ((values.orderType !== 'glovo'  && values.orderType !== 'physical')|| val.value === 'personal') )}
                                    value={values.delivery}
                                    onChange={value => setFieldValue('delivery', value)} />
                            </Pane>

                            <Pane>
                                <SelectField
                                    label="Modalitate de plata"
                                    onChange={handleChange}
                                    name="paymentTypeId">
                                    {this.props.paymentTypes.filter(val => (
                                        (values.orderType !== 'glovo' || val.handler === 'third-party') &&
                                        (values.orderType === 'glovo' || val.handler !== 'third-party'))).map(paymentType => (
                                        <option value={paymentType.id} selected={paymentType.id === values.paymentTypeId}>{paymentType.name}</option>
                                    ))}
                                </SelectField>
                            </Pane>
                        </Pane>

                        {values.orderType !== 'glovo' ? <>
                            <Pane>
                                <Heading size={300} marginTop={10}>Copiaza date facturare</Heading>
                                <OrderAutocomplete width={300} value={values.reference} onChange={(val) => {
                                    setFieldValue('entityType', val.entityType);
                                    setFieldValue('email', val.email);
                                    setFieldValue('billingName', val.billingName);
                                    setFieldValue('billingAddress', val.billingAddress);
                                    // setFieldValue('billingCounty', val.billingCounty);
                                    // setFieldValue('billingCity', val.billingCity);
                                    setFieldValue('billingLocation', val.billingLocation);
                                    setFieldValue('companyName', val.companyName);
                                    setFieldValue('vatNumber', val.vatNumber);
                                    setFieldValue('registryNumber', val.registryNumber);
                                    setFieldValue('billingPhoneNumber', val.billingPhoneNumber);

                                    setFieldValue(`deliveryAddress`, val.deliveryAddress);
                                    // setFieldValue(`deliveryCounty`, val.billingCounty);
                                    // setFieldValue(`deliveryCity`, val.billingCity);
                                    setFieldValue('deliveryLocation', val.deliveryLocation);

                                    setFieldValue(`deliveryName`, val.deliveryName);
                                    setFieldValue(`deliveryPhoneNumber`, val.deliveryPhoneNumber);
                                }} />
                            </Pane>
                            <Pane display="flex" marginTop={10}>
                                <Pane minWidth="400px" marginRight={10}>
                                    <Heading size={500} marginBottom={8} textTransform="uppercase" borderBottom="1px solid #aaaaaa">Date Contact &amp; Facturare</Heading>

                                    <Heading size={400} marginBottom={4}>Persoana Fizica / Juridica</Heading>
                                    <SegmentedControl
                                        marginBottom={16}
                                        width={300}
                                        options={textValues.entity}
                                        value={values.entityType}
                                        onChange={value => setFieldValue('entityType', value)} />

                                    <TextInputField
                                        required
                                        label="Email"
                                        name="email"
                                        description="Adresa de email a cumparatorului"
                                        value={values.email || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.email} />

                                    <TextInputField
                                        required
                                        label="Nume (Facturare)"
                                        name="billingName"
                                        description="Numele care apare pe factura"
                                        value={values.billingName || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.billingName} />

                                    {/* <Heading size={300} marginTop={10}>Judet (Facturare)</Heading> */}
                                    {/* <CountyAutocomplete width={300} value={values.billingCounty} onChange={(val) => {
                                        setFieldValue(`billingCounty`, val)
                                    }} />

                                    <Heading size={300} marginTop={10}>Oras (Facturare)</Heading>
                                    <CityAutocomplete width={300} county={values.billingCounty ? values.billingCounty.id : null} value={values.billingCity} onChange={(val) => {
                                        setFieldValue(`billingCity`, val)
                                    }} /> */}

                                    <Heading size={300} marginTop={10}>Locatie (Facturare)</Heading>
                                    <LocationAutocomplete width={300} location={values.billingLocation ? values.billingLocation.id : null} value={values.billingLocation} onChange={(val) => {
                                        setFieldValue(`billingLocation`, val)
                                    }} />

                                    <TextInputField
                                        required
                                        label="Detalii Adresa (Facturare)"
                                        name="billingAddress"
                                        description="Adresa care apare pe factura"
                                        value={values.billingAddress || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.billingAddress} />

                                    <TextInputField
                                    marginTop={10}
                                        required
                                        label="Telefon (Facturare)"
                                        name="billingPhoneNumber"
                                        description="Nr. tel. care apare pe factura"
                                        value={values.billingPhoneNumber || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        validationMessage={errors.billingPhoneNumber} />

                                    {values.entityType === "pj" ? <>
                                        <TextInputField
                                            required
                                            label="Nume companie"
                                            name="companyName"
                                            description="Numele legal al companiei"
                                            value={values.companyName || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.companyName} />
                                        <TextInputField
                                            required
                                            label="CIF/CUI"
                                            name="vatNumber"
                                            description="Ex. 41064338"
                                            value={values.vatNumber || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.vatNumber} />
                                        <TextInputField
                                            required
                                            label="Nr. Inregistrare Registrul Comertului"
                                            name="registryNumber"
                                            description="Ex. J29/1696/2019"
                                            value={values.registryNumber || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.registryNumber} />
                                    </> : null}
                                </Pane>
                                <Pane minWidth="400px">
                                    {values.delivery === "delivery" ? <>
                                        <Heading size={500} marginBottom={8} textTransform="uppercase" borderBottom="1px solid #aaaaaa">Date Livrare</Heading>
                                        <Heading size={400} marginBottom={4}>Datele de facturare sunt aceleasi cu datele de livrare?</Heading>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setFieldValue(`deliveryName`, values.billingName);
                                                setFieldValue(`deliveryAddress`, values.billingAddress);
                                                // setFieldValue(`deliveryCounty`, values.billingCounty);
                                                // setFieldValue(`deliveryCity`, values.billingCity);
                                                setFieldValue('deliveryLocation', values.billingLocation);
                                                setFieldValue(`deliveryName`, values.billingName);
                                                setFieldValue(`deliveryPhoneNumber`, values.billingPhoneNumber);

                                            }}
                                            marginBottom={15}
                                        >Copiaza date facturare</Button>

                                        <TextInputField
                                            required
                                            label="Nume persoana contact (Livrare)"
                                            name="deliveryName"
                                            description="Numele persoanei de contact"
                                            value={values.deliveryName || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.deliveryName} />

                                        {/* <Heading size={300} marginTop={10}>Judet (Livrare)</Heading>
                                        <CountyAutocomplete width={300} value={values.deliveryCounty} onChange={(val) => {
                                            setFieldValue(`deliveryCounty`, val)
                                        }} />

                                        <Heading size={300} marginTop={10}>Oras (Livrare)</Heading>
                                        <CityAutocomplete width={300} county={values.deliveryCounty ? values.deliveryCounty.id : null} value={values.deliveryCity} onChange={(val) => {
                                            setFieldValue(`deliveryCity`, val)
                                        }} /> */}

                                        <Heading size={300} marginTop={10}>Locatie (Livrare)</Heading>
                                        <LocationAutocomplete width={300}
                                            location={values.deliveryLocation ? values.deliveryLocation.id : null}
                                            value={values.deliveryLocation} onChange={(val) => {
                                                setFieldValue(`deliveryLocation`, val)
                                            }}/>

                                        <TextInputField
                                            required
                                            label="Detalii Adresa (Livrare)"
                                            name="deliveryAddress"
                                            description="Adresa la care se va face livrarea"
                                            value={values.deliveryAddress || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.deliveryAddress} />

                                        <TextInputField
                                            marginTop={10}
                                            required
                                            label="Telefon persoana contact (Livrare)"
                                            name="deliveryPhoneNumber"
                                            description="Nr. telefon al persoanei de contact"
                                            value={values.deliveryPhoneNumber || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validationMessage={errors.deliveryPhoneNumber} />

                                        <TextInputField
                                            marginTop={10}
                                            required
                                            label="Cost Transport"
                                            name="shippingCost"
                                            description="Costul transportului"
                                            value={values.shippingCost || 0}
                                            onChange={handleChange}
                                            onBlur={handleBlur}/>
                                    </> : null}
                                </Pane>
                            </Pane> </>: null }

                        {/* <Heading size={300} marginTop={10}>Precomanda?</Heading>
                        <OrderAutocomplete width={300} value={values.preorder} onChange={(val) => {

                            setFieldValue('products', val.products.filter(product => {
                                return !!product.preorder
                            }).map(product => {
                                return {
                                    preorder: false,
                                    label: product.product.sku + ' - ' + product.product.title,
                                    id: product.product.id,
                                    quantity: product.quantity,
                                    price: product.unitPrice,
                                    vatBracket: product.product.vatBracket,
                                }
                            }));

                            setFieldValue(`preorder`, val)
                        }} /> */}


                        <Pane marginTop={32} marginBottom={16} padding={16} background="tint2">
                            <Heading size={600}>Produse Comandate</Heading>
                        </Pane>
                        <Pane>
                            <FieldArray name="items" render={arrayHelpers => (<>
                                <Button type="button" onClick={() => arrayHelpers.push({
                                    name: 'test', quantity: 1, price: 0, type: 'product'
                                })}>
                                    Adauga Produs
                                </Button>
                                <Button marginLeft={10} type="button" onClick={() => arrayHelpers.push({
                                    name: 'teest', quantity: 1, price: 0, type: 'card'
                                })}>
                                    Adauga Carte
                                </Button>

                                <Table>
                                    <Table.Head>
                                        <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                            Pre
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Tip
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                                            Nume
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
                                            Cantitate
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Pret Unitar
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Total Linie
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Info
                                        </Table.TextHeaderCell>
                                    </Table.Head>
                                    <Table.Body>
                                        {values.items ? values.items.map((item, index) =>
                                            <Table.Row>
                                                <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                                    <Checkbox
                                                        name={`items.${index}.preorder`}
                                                        checked={item.preorder || false}
                                                        onChange={handleChange} />
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} display="flex" justifyContent="center">
                                                    <img style={{height: '47px', width: 'auto'}}
                                                    alt="Thumbnail"
                                                    src={
                                                            item.thumbnail  && item.type === 'product' ?
                                                                process.env.REACT_APP_IMAGE_STORAGE + '/thumb_' + item.thumbnail + '.webp'
                                                                :
                                                            item.thumbnail && item.type === 'card' ?
                                                                process.env.REACT_APP_CARD_IMAGE_BUCKET + item.thumbnail : null
                                                            }/>
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {item.type}
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
                                                    { item.type === 'product' ?
                                                         <ProductAutocomplete width={400} value={{ ...item, label: (typeof item.label !== 'undefined' ? item.label : item.sku + " - " + item.title) }} onChange={(val) => {
                                                            setFieldValue(`items.${index}`, { ...item, ...val, price: productPrice({...item, ...val}, values.orderType)});
                                                        }} /> :
                                                      item.type === 'card' ?
                                                      <CardAutocomplete width={400} value={{ ...item, label: (typeof item.label !== 'undefined' ? item.label : item.code + " - " + item.name) }} onChange={(val) => {
                                                            setFieldValue(`items.${index}`, { ...item, ...val});
                                                        }} />

                                                        : null}
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
                                                    <Pane display="flex" alignItems="center" flexDirection="column">
                                                        <TextInputField width="100%" marginTop={-20} margin={0} padding={0}
                                                            name={`items.${index}.quantity`}
                                                            value={item.quantity}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                        />
                                                    </Pane>
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    <TextInputField
                                                        required
                                                        name={`items.${index}.price`}
                                                        value={item.price}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />

                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {item ? Math.round((item.quantity * item.price) * 100) / 100 : 0}
                                                    &nbsp;Lei
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} flex={true} flexDirection="row">
                                                    <Button intent="danger" onClick={() => arrayHelpers.remove(index)} type="button">Sterge</Button>
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {item.inStock > 0  ? `Stoc - ${item.inStock}` : 'Stoc Epuizat'} <br/> TVA {item.vatBracket || 19}%
                                                </Table.TextCell>
                                            </Table.Row>
                                        ) : null}

                                        {values.delivery === 'delivery' ?
                                            <Table.Row>
                                                <Table.TextCell  flexBasis={50} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={400} flexShrink={0} flexGrow={0}>
                                                    Transport
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={120} flexShrink={0} flexGrow={0}>
                                                    1
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {values.shippingCost} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {values.shippingCost} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                            </Table.Row> : null}

                                        <Table.Row>
                                            <Table.TextCell  flexBasis={50} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={400} flexShrink={0} flexGrow={0}>
                                                <strong>TOTAL</strong>
                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={120} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                            {
                                                    Math.round((values.items
                                                        .reduce(
                                                            (prev, curr) => prev + (curr.quantity * curr.price),
                                                            values.delivery === 'delivery' ? values.shippingCost : 0
                                                        )) * 100) / 100

                                                } Lei
                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </>)}/>

                        </Pane>

                        {/* <Pane>
                            <FieldArray name="products" render={arrayHelpers => (<>
                                <Button type="button" onClick={() => arrayHelpers.push({ label: '', quantity: 1, price: 0, vatBracket: 0 })} marginBottom={8}>Produs Nou</Button>

                                <Table>
                                    <Table.Head>
                                        <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                            PRE
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
                                            Produs
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
                                            Cantitate
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Pret Unitar
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            PU Fara TVA
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Pret Total
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            TVA
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Total Linie
                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>

                                        </Table.TextHeaderCell>
                                        <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                            Info
                                        </Table.TextHeaderCell>
                                    </Table.Head>
                                    <Table.Body>
                                        {values.products ? values.products.map((product, index) => (
                                            <Table.Row key={product.id}  height={100}>
                                                <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                                    <Checkbox
                                                        name={`products.${index}.preorder`}
                                                        checked={product.preorder || false}
                                                        onChange={handleChange} />
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} display="flex" justifyContent="center">
                                                    <img style={{height: '64px', width: 'auto'}}
                                                    alt="Product Thumbnail"
                                                    src={
                                                            product.thumbnail ?
                                                                process.env.REACT_APP_IMAGE_STORAGE + '/thumb_' + product.thumbnail + '.webp'
                                                                : null
                                                            }/>
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
                                                    <ProductAutocomplete width={400} value={{ ...product, label: (typeof product.label !== 'undefined' ? product.label : product.sku + " - " + product.title) }} onChange={(val) => {
                                                        setFieldValue(`products.${index}`, { ...product, ...val, price: productPrice({...product, ...val}, values.orderType)});
                                                        console.log(product);
                                                    }} />
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
                                                    <Pane display="flex" alignItems="center" flexDirection="column">
                                                        <TextInputField width="100%" marginTop={-20} margin={0} padding={0}
                                                            name={`products.${index}.quantity`}
                                                            value={product.quantity}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                        />
                                                    </Pane>

                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    <TextInputField
                                                        required
                                                        name={`products.${index}.price`}
                                                        value={product.price}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />

                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {product ? getPriceNoVat(product.price, product.vatBracket) : 0}
                                                    &nbsp;Lei
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {product ? getPriceNoVat(product.quantity * product.price : 0}
                                                    &nbsp;Lei
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {product ? getVat(product.quantity * product.price, product.vatBracket) : 0}
                                                    &nbsp;Lei
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {product ? Math.round((product.quantity * product.price) * 100) / 100 : 0}
                                                    &nbsp;Lei
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} flex={true} flexDirection="row">
                                                    <Button intent="danger" onClick={() => arrayHelpers.remove(index)} type="button">Sterge</Button>
                                                </Table.TextCell>
                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {product.inStock  ? `Stoc - ${product.inStock}` : 'Stoc Epuizat'} <br/> TVA {product.vatBracket}%
                                                </Table.TextCell>
                                            </Table.Row>
                                        )) : null}

                                        {values.delivery === 'delivery' ?
                                            <Table.Row>
                                                <Table.TextCell  flexBasis={50} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={400} flexShrink={0} flexGrow={0}>
                                                    Transport
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={120} flexShrink={0} flexGrow={0}>
                                                    1
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {values.shippingCost} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {getPriceNoVat(values.shippingCost, 19)} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {getPriceNoVat(values.shippingCost, 19)} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {getVat(values.shippingCost, 19)} Lei
                                                </Table.TextCell>
                                                <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                    {values.shippingCost} Lei
                                                </Table.TextCell>
                                            </Table.Row> : null}

                                        <Table.Row>
                                            <Table.TextCell  flexBasis={50} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={400} flexShrink={0} flexGrow={0}>
                                                <strong>TOTAL</strong>
                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={120} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>

                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                {
                                                    Math.round((values.products
                                                        .reduce(
                                                            (prev, curr) => prev + (getPriceNoVat(curr.quantity * curr.price, curr.vatBracket)),
                                                            values.delivery === 'delivery' ? getPriceNoVat(values.shippingCost, 19) : 0
                                                        )) * 100) / 100

                                                } Lei
                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                {Math.round((
                                                values.products.reduce((prev, curr) => prev + getVat(curr.quantity * curr.price, curr.vatBracket),
                                                    values.delivery === 'delivery' ? getVat(values.shippingCost, 19) : 0
                                                )) * 100) / 100 } Lei
                                            </Table.TextCell>
                                            <Table.TextCell  flexBasis={100} flexShrink={0} flexGrow={0}>
                                                {Math.round((values.products.reduce((prev, curr) => prev + (curr.quantity * curr.price),
                                                 values.delivery === 'delivery' ?  parseFloat(values.shippingCost) : 0)) * 100) / 100 } Lei
                                            </Table.TextCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </>)} />
                        </Pane> */}

                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}
