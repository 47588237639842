import { Button, TextInputField } from 'evergreen-ui';
import React from 'react';

import ProductAutocomplete from './ProductAutocomplete';

const ProductRecipe = (props) => {
    return (
        <>
            <ProductAutocomplete 
                key={props.index}
                width={300} 
                blankOption={true}
                value={props.data} 
                onChange={(val) => {
                    props.setFieldValue(`recipe.${props.index}`, {...props.data, 
                        sourceProductId: val.productId,
                        label: val.label
                    })
                }}/>

            
            <TextInputField
                required
                label="Cantitate"
                name={`recipe.${props.index}.sourceQuantity`}
                value={props.data ? props.data.sourceQuantity : ""}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />

            <Button intent="danger" onClick={() => props.onRemove(props.index)} type="button">Sterge</Button>
        </>
    )
}


export default ProductRecipe;