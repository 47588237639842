import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from '../../common/DataTable/DataTable';
import { Button, Pane, Text } from 'evergreen-ui';
import Request from '../../../util/Api'
import Config from '../Config';


import { getCardExpansions } from '../actions/cardExpansionActions';
import CardExpansionLabelModal from '../components/CardExpansionLabelModal';

class ListCardExpansions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            labelModal: false
        }

        this.resync = this.resync.bind(this);
        this.resyncGames = this.resyncGames.bind(this);
        this.closeLabelModal = this.closeLabelModal.bind(this);
    }

    async resync() {
        this.setState({
            loading: true
        })
        await Request.post(Config.Endpoints.resyncExpansions);

        this.setState({
            loading: false
        })
    }

    async resyncGames() {
        this.setState({
            loading: true
        })
        await Request.post(Config.Endpoints.resyncGames);

        this.setState({
            loading: false
        })
    }

    handleTableChange = (opts) => {
        this.props.dispatch(getCardExpansions(opts));
    }

    openLabelModal = () => {
        this.setState({
            labelModal: true
        })
    }

    closeLabelModal = () => {
        this.setState({
            labelModal: false
        })
    }

    render() {
        if(!this.props.cardExpansions || this.props.cardExpansions.length === 0) {
            return <Text>Se incarca...</Text>
        }

        return (<>
            <CardExpansionLabelModal
                open={this.state.labelModal}
                onClose={this.closeLabelModal}
            />
            <Pane display="flex" marginBottom={16}>
                {this.state.loading ? <Text>Se incarca...</Text> : <>
                    <Button onClick={this.resync}>Resincronizeaza toate seturile</Button>
                    <Button onClick={this.resyncGames} marginLeft={20}>Resincronizeaza toate jocurile</Button>
                    <Button onClick={this.openLabelModal} marginLeft={20}>Genereaza label-uri</Button>
                </>
                }
            </Pane>
            <DataTable
                defaultFilter='updatedAt'
                defaultDirection='desc'
                columns={[
                    { id: 'id', label: 'Id Intern', visible: false },
                    { id: 'code', label: 'Cod' },
                    { id: 'name', label: 'Nume' },
                    { id: 'releaseDate', label: 'Lansare', decorator: 'date'},
                    { id: 'cardsSynced', label: 'Synced', decorator: 'boolean'}
                ]}

                data={this.props.cardExpansions.data}

                updateKey={this.props.cardExpansions.data}
                page={this.props.cardExpansions.page}
                pageSize={this.props.cardExpansions.pageSize}
                pageCount={this.props.cardExpansions.pageCount}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/card-expansions/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/card-expansions/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return {
        cardExpansions: state.cardExpansions,
        loading: false
    }
}

export default connect(mapStateToProps)(ListCardExpansions);
