import React from 'react';
import { Pane, Text, Checkbox } from 'evergreen-ui';
import ReactHtmlParser from 'react-html-parser';

import moment from 'moment';

export default function InfoLine(props) {
    return (
        <Pane display="flex" marginBottom={4} borderBottom="default"> 
            <Pane padding={8} width={150} minWidth={150} >
                <Text><strong>{props.label}</strong></Text>
            </Pane>
            <Pane flexGrow={1} padding={8}>
                 {  props.type === "bool" ? <Checkbox checked = {props.value} margin={3} disabled/> : 
                    props.type === "date" ? <Text>{moment(props.value).format('D MMM YYYY HH:mm:ss')}</Text> :
                    props.type === "rich-text" ? <Text>{ReactHtmlParser(props.value)}</Text> :
                    props.type === "map" ? <Text>{props.map[props.value]}</Text> :
                    <Text>{props.value}</Text>}
            </Pane>
        </Pane>
    )
}