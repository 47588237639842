import React, { Component } from 'react';
import { Formik } from 'formik';
import { TextInputField, Button, Pane, Textarea, InlineAlert } from 'evergreen-ui';

export default class SupplierForm extends Component {
    render() {
        return (
            <Formik initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={this.props.validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit = {(values) => {
                    this.props.onSave(values);
                }}
            >
                {({errors, handleChange, handleBlur, handleSubmit, values}) => {
                    errors = Object.assign({}, this.props.errors, errors);

                    return <form>
                        <TextInputField
                            required
                            label="Nume"
                            name="name"
                            description="Numele Furnizorului"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            validationMessage={errors.name}/>

                        <Textarea
                            id="notes"
                            name="notes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.notes}
                            value={values.notes}
                            placeholder="Alte informatii despre furnizor"
                        />
                            {errors.notes ? 
                            <InlineAlert intent="danger">{errors.notes}</InlineAlert>
                        : null}


                        <Pane borderTop="default" marginTop={16} paddingTop={16}>
                            <Button type="submit" onClick={handleSubmit}>
                                Salveaza
                            </Button>
                        </Pane>
                    </form>
                }}
            </Formik>
        );
    }
}