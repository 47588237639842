import React, { Component } from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import { Button, Pane } from 'evergreen-ui';

import KImageComponent from './KImageComponent';
import configuration from '../../../util/Configuration';
import KDropzoneDialog from './KDropzoneDialog';

const SortableItem = SortableElement((props) => 
    <Pane display="inline-block" cursor="pointer">
        <KImageComponent 
            onRemove={props.onRemove}
            image={props.value.id ?
                (`${configuration.images}/thumb_${props.value.image}.jpg`) : 
                    props.value.data}/>
    </Pane>
)

const SortableList = SortableContainer((props) => {
    const items = props.items;

    return (
        <Pane display="flex" flexWrap="wrap">
            {items.map((value, index) => (
                <SortableItem onRemove={() => { props.onRemove(index) }} 
                    key={`product-image-${index}`} 
                    index={index} 
                    value={value}/>
            ))}
        </Pane>
    )
})

export default class KFileDrop extends Component {
    constructor (props) {
        super(props);

        this.state = {
            open: false
        };
    }

    readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleRemove = (index) => {
        this.updateFiles(this.props.form.values.images.filter((v, i) => i !== index));
    }

    handleSave = async (files) => {
        let processedFiles = await Promise.all(files.map(async file => {
            const data = await this.readFileAsync(file);
            
            return {
                data
            }
        }))

        processedFiles = ((this.props.form.values[this.props.field.name] || []).concat(processedFiles))
        .map((image, index) => ({
            ...image,
            position: index
        }));

        this.updateFiles(processedFiles);

        this.setState({
            open: false
        })
    }

    updateFiles= (files) => {
        this.props.form.setFieldValue(this.props.field.name, files);
    }

    handleSortEnd = ({oldIndex, newIndex}) => {
        let reordered = arrayMove((this.props.form.values[this.props.field.name] || []), oldIndex, newIndex);
        reordered = reordered.map((image, index) => {
            return {
                ...image,
                position: index
            }
        });

        this.updateFiles(reordered);
    }

    render() {
        return (
            <Pane marginBottom={16}>
                <Button type="button" onClick={this.handleOpen.bind(this)}>
                    Adauga Imagini
                </Button>
                <Pane>
                    <SortableList axis="xy" items={this.props.form.values[this.props.field.name] || []}
                        onSortEnd={this.handleSortEnd}
                        onRemove={this.handleRemove}
                        />
                </Pane>

                <KDropzoneDialog 
                    onConfirm={this.handleSave}
                    onClose={this.handleClose}
                    isOpen={this.state.open}/>
            </Pane>
        )
    }
}