import ModuleBootstrapper from '../common/ModuleBootstrapper/ModuleBootstrapper';
import Card from './models/Card';
import * as yup from 'yup';

const config =  {
    name: 'cards',
    actions: [
        { type: 'request', name: 'getAll' },
        { type: 'request', name: 'getOne' },
        { type: 'request', name: 'create' },
        { type: 'request', name: 'edit' },
        { type: 'request', name: 'delete' }
    ],
    endpoints: {
        cards: '/card-games/cards',
        card: '/card-games/cards/[id]',
        resyncPrices: '/card-games/sync-prices'
    },
    defaults: {
        createCard: {
           
        }
    },
    validations: {
        card: yup.object().shape({
           
        })
    }
}

const Config ={
    Actions: ModuleBootstrapper.getActions(config.name, config.actions),
    Endpoints: config.endpoints,
    Defaults: config.defaults,
    Validations: config.validations,
    ...ModuleBootstrapper.getUtils(),
    Structures: {
        Card
    },
}

export default Config;