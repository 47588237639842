import React from 'react';
import { Card, Button, Pane } from 'evergreen-ui';

const KImageComponent = (props) => (
    <Card margin={12}>
        <Pane>
            <img alt="component" src={props.image} style={{height: "100px"}}/>
        </Pane>
        {!props.isView ? <Pane>
            <Button type="button" intent="danger" width="100%" onClick={props.onRemove}>
                Sterge
            </Button>
        </Pane> : null}
    </Card>
)

export default KImageComponent;