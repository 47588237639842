import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { getStockOperations } from '../actions/StockOperations';
import DataTable from '../../common/DataTable/DataTable';
import { Button, Pane, Text } from 'evergreen-ui';
import Request from '../../../util/Api'
import Config from '../Config';

import { getCards } from '../actions/CardActions';
import { Link } from 'react-router-dom';

class ListCards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.resync = this.resync.bind(this);
    }

    async resync() {
        this.setState({
            loading: true
        })
        await Request.post(Config.Endpoints.resyncPrices);

        this.setState({
            loading: false
        })
    }

    handleTableChange = (opts) => {
        this.props.dispatch(getCards(opts));
    }

    render() {
        return (<>
            <Pane display="flex" marginBottom={16}>
                <Pane>
                    <Link to="/card-expansions">
                        <Button intent="none" appearance="primary">
                            Vezi Extensii
                        </Button>
                    </Link>

                    {this.state.loading ? <Text>Se incarca...</Text> :
                    <Button onClick={this.resync} marginLeft={10}>Resincronizeaza Preturile</Button>}
                </Pane>
            </Pane>
            <DataTable
                defaultFilter='updatedAt'
                defaultDirection='desc'
                columns={[
                    { id: 'id', label: 'Id' },
                    { id: 'cardMarketId', label: 'CMId' },
                    {id: 'thumbnail', label: 'Imagine', decorator: card => (
                        <img alt="card" style={{height: '48px', width: 'auto'}}
                            src={card.thumbnail ?
                                process.env.REACT_APP_CARD_IMAGE_BUCKET + card.thumbnail : null}/>), options: [
                        { label: 'Toate', value: 'all'},
                        { label: 'Da', value: 'y'},
                        { label: 'Nu', value: 'n'},
                    ]},
                    { id: 'views', label: 'Viz' },
                    { id: 'name', label: 'Nume' },
                    { id: 'code', label: 'Cod' },
                    { id: 'rarity', label: 'Raritate'},
                    { id: 'variantName', label: 'Varianta' , options: [
                        { label: 'Toate', value: '' },
                        { label: 'Reverse Holo', value: 'reverseHolofoil' },
                    ]},
                    { id: 'quantity', label: 'Cant.', options: [
                        { label: 'Toate', value: 'all'},
                        { label: 'In stoc', value: 'y'},
                        { label: 'Minim 3', value: '3'},
                    ]},
                    { id: 'price', label: 'Pret' },
                    { id: 'priceLocked', label: 'Pret Fix'}
                ]}

                data={this.props.data}

                updateKey={this.props.data}
                page={this.props.page}
                pageSize={this.props.pageSize}
                pageCount={this.props.pageCount}

                onTableChange={this.handleTableChange}

                itemMenu={[
                    { getLink: (item) => `/cards/${item[0]}`, label: 'Detalii', icon: 'eye-open' },
                    { getLink: (item) => `/cards/${item[0]}/edit`, label: 'Editeaza', icon: 'edit' }
                ]}
            />
        </>)
    }
}

function mapStateToProps(state) {
    return state.cards
}

export default connect(mapStateToProps)(ListCards);
