import React, { Component } from 'react';
import { Button, Dialog, Heading, Pane, Text } from 'evergreen-ui';
import Request from '../../../util/Api';
import moment from 'moment';
import { round } from 'lodash';

class StockModal extends Component {
   

    constructor (props) {
        super(props);

        this.classes = props.classes;
        this.onClose = this.onClose.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.state = {
            stocks: []
        }
    }

    fetchData = async () => {
        const results = await Request.get(`/products/${this.props.product.id}/stocks`);

        this.setState({
            stocks: results.data
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.open === false && this.props.open === true) {
            this.fetchData();
        }
    }

    onClose = () => {
        this.setState({});
        this.props.onClose();
    }
   
    render () {
        let totalOutQty = 0;

        for(let i in this.state.stocks.outputs) {
            totalOutQty = totalOutQty + this.state.stocks.outputs[i].quantity;
        }

      return (<Dialog
        width={1400}
        isShown={this.props.open}
        title= {"Raport Stocuri"}
        hasFooter={false}
        onCloseComplete={this.onClose}
        onClose={this.onClose}>
            <Pane display="flex" justifyContent="center">
                <Pane minWidth={300} marginRight={20}>
                    <Heading flexGrow={1}>IN</Heading>
                    <Pane>
                        {this.state.stocks.inputs ? this.state.stocks.inputs.map(input => (
                            <Pane marginBottom={10}>
                                <Pane>
                                    <Text><strong>Factura {input.documentName}/{moment(input.documentDate).format('DD.MM.YYYY')}</strong></Text>
                                </Pane>
                                <Pane>
                                    <Text>NIR {input.type}-{input.number}/{moment(input.date).format('DD.MM.YYYY')}</Text>
                                </Pane>
                                <Pane>
                                    <Text>Cantitate: <strong>{input.quantity}</strong> la <strong>{input.value}</strong> Lei/buc</Text>
                                </Pane>
                                {input.quantity > 0 ? 
                                    <Pane>
                                        <Text>Pret vanzare minim (cu TVA): <strong>{round(input.value * (100 + this.props.product.vatBracket) / 100, 2)}</strong> Lei</Text>
                                    </Pane> : null}

                            </Pane> 
                        )) : null}
                    </Pane>
                </Pane>
                <Pane  minWidth={300} marginRight={20}>
                    <Heading flexGrow={1}>OUT (Total: {totalOutQty}) </Heading>
                    <Pane>
                        {this.state.stocks.outputs ? this.state.stocks.outputs.map(output => (
                            <Pane marginBottom={10}>
                                <Pane>
                                    <Text>
                                        <a href={`/orders/${output.orderId}`} target="_blank" rel="noreferrer">
                                            <strong>Comanda #{output.orderNumber}/{moment(output.createdAt).format('YYYY-MM-DD')} ({output.status})</strong>
                                        </a>
                                    </Text>
                                </Pane>
                                <Pane>
                                    <Text>{output.billingName}</Text>
                                </Pane>
                                <Pane>
                                    <Text>Cantitate: <strong>{output.quantity}</strong> la <strong>{output.unitPrice}</strong> Lei/buc</Text>
                                </Pane>
                            </Pane> 
                        )) : null}
                    </Pane>
                </Pane>
                <Pane>
                    <Heading flexGrow={1}>Stock</Heading>
                    <Pane>
                        {this.state.stocks.stocks ? this.state.stocks.stocks.map((stock, index) => (
                            <Pane marginBottom={10}>
                                <Pane>
                                    <Text>[{index}] - IN: {stock.entryType || '-'} / OUT: {`${stock.exitType} ( ${stock.exitObjectId } )` || '-'} / HOLD: {`${stock.lockedType} ( ${stock.lockedObjectId} )` || "-"}</Text>
                                </Pane>
                            </Pane> 
                        )) : null}
                    </Pane>
                </Pane>
            </Pane>

            
           
            <Button type="button" onClick={() => {
                this.props.onClose();
            }}>Inchide</Button>
             
            
      </Dialog>)
    }
}

export default StockModal;