import moment from "moment";
import { StockOperationLabels } from "./StockOperationLabels";
import { StockOperationStatuses } from "./StockOperationStatuses";
import { StockOperationTypes } from "./StockOperationTypes";

export class StockOperation {
    constructor(props) {
        this.id = props.id || 0;
        this.supplierId = props.supplierId || 0;
        this.stockAccountId = props.stockAccountId || 0;

        this.type = props.type;
        this.label = props.label || 'STD';

        this.typeDetails = props.typeDetails;
        this.number = props.number || 0;
        this.date = props.date ? moment(props.date) : null;
        this.status = props.status;
        this.documentName = props.documentName || 'Fara Document';
        this.documentDate = props.documentDate ? moment(props.documentDate) : null;
        this.currency = props.currency;
        this.exchange = props.exchange;
        this.sellThrough = props.sellThrough ? props.sellThrough.reduce((acc, line) => {
            return acc + line.exitValue || 0
        }, 0) : 0;

        this.items = (props.items || []).map((item) => {
            let lineValue = 0;
            let lineProfit = 0;
            if(props.sellThrough) {
                for(const sellThroughLine of props.sellThrough) {
                    if(sellThroughLine.cardId == item.cardId && sellThroughLine.productId == item.productId) {
                        lineValue = sellThroughLine.exitValue + lineValue || 0;
                        lineProfit = (sellThroughLine.exitValue || 0 - sellThroughLine.entryValue || 0) + lineProfit || 0
                    }
                }
            }

            return {
                id: item.type === 'Carte' && typeof item.card !== 'undefined' ? item.cardId :
                    item.type === 'Produs' && typeof item.product !== 'undefined' ? item.productId :
                    typeof item.product === 'undefined' ? item.id :
                    null,
                card: item.type === 'Carte' && typeof item.card !== 'undefined' ? item.card : null,
                position: item.position,
                type: item.type,
                quantity: item.quantity,
                value: item.value,
                adjustedValue: item.adjustedValue,
                name: item.name ? item.name :
                        item.card ? item.card.code + ' - ' + item.card.name + ' - ' + item.card.rarity + ' [' + item.card.variantName + ']' :
                        item.product ? item.product.title :
                        'Fara Nume',
                price: item.price ? item.price :
                        item.card ? item.card.price :
                        item.product ? item.product.price : 0,
                thumbnail: item.thumbnail ? item.thumbnail :
                            item.card ? item.card.thumbnail :
                            item.product ? item.product.thumbnail : 0,
                lineValue,
                lineProfit
            }
        });

        this.documentSeries =  `${this.type}-${this.number || 'x'}/${this.date ?
            moment(this.date).format('DD.MM.YYYY') : 'xx.xx.xxxx'}`;

        this.value = props.value;
        this.createdAt = props.createdAt ? moment(props.createdAt) : null;
        this.updatedAt = props.updatedAt ? moment(props.updatedAt) : null;


        //Matched Labels
        let labelText = StockOperationLabels.find(label => label.id === this.label);
        this.labelText = labelText ? labelText.label : false;

        let typeText = StockOperationTypes.find(type => type.id === this.type);
        this.typeText = typeText.label;

        let statusText = StockOperationStatuses.find(status => status.id === this.status);
        this.statusText = statusText ? statusText.label : 'N/A';
    }

    export() {
        return {
            id: this.id || null,
            supplierId: this.supplierId,
            stockAccountId: this.stockAccountId,
            type: this.type,
            label: this.label,
            typeDetails: this.typeDetails,
            number: this.number || 0,
            date: this.date || null,
            status: this.status,
            documentName: this.documentName,
            documentDate: this.documentDate,
            currency: this.currency,
            exchange: this.exchange,
            items: this.items || []
        }
    }
}
