import { Table, Text } from 'evergreen-ui';
import React from 'react';
import moment from 'moment';

import InfoBlock from '../../common/InfoBlock/InfoBlock';
import InfoLine from '../../common/InfoBlock/InfoLine';

export default function CartInfoBlock(props) {
    console.log(props.cart);
    return <InfoBlock 
        flex="50%" 
        marginRight={16} 
        label="Detalii Cos"
    >
        <InfoLine label="Id Intern" value={props.cart.id}/>
        <InfoLine lable="Owner" value={props.cart.user}/>
        <InfoLine label="Creat" value={(moment(props.cart.createdAt).format('YYYY-MM-DD HH:mm:ss'))}/>
        <InfoLine label="Ultima Modificare" value={(moment(props.cart.updatedAt).format('YYYY-MM-DD HH:mm:ss'))}/>

        <InfoLine label="Valoare Totala" value={props.cart.totalPrice + ' RON'}/>
         
        <Table>
            <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    #
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
                    Produs
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Cantitate
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Unitar
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    Pret Total
                </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
                    {(props.cart.products && props.cart.products.length > 0) ? (props.cart.products).map((product, index) => (
                        <Table.Row>
                            <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                                {index + 1}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} title={product.label}>
                                <strong>{product.title}</strong>
                                <br/>
                                {product.sku}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.CartProduct.quantity}
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.totalPrice} Lei
                            </Table.TextCell>
                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                {product.totalPrice * product.CartProduct.quantity} Lei
                            </Table.TextCell>
                        </Table.Row>
                    )) : <Text>Nu exista produse in cos</Text>}
            </Table.Body>
        </Table>

    </InfoBlock>
}