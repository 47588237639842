import React, { Component } from 'react';
import { Table, Popover, IconButton, Menu, Position, TextDropdownButton, MoreIcon, Pagination, Pane, colors, FilterIcon, CaretUpIcon, CaretDownIcon } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class DataTable extends Component {
    constructor(props) {
        super(props);

        this.loading = false;
        this.oldKey = 1337;

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.stale = true;
        this.chunkSize = this.props.pageSize || 20;
        this.search = this.props.defaultSearch || '';
        this.start = 0;
        this.filterColumn = this.props.defaultFilter || null;
        this.filterDirection = this.props.defaultDirection || null;
        this.page = this.props.page || 1;
        this.pageCount = this.props.pageCount || 1;
        
        this.extraFilters = {};

        this.tableData = [];

        this.searchTimeout = null;
        this.loadTimeout = null;

        this.oldKey = this.props.updateKey || this.props.data;

        this.props.onTableChange({
            start: this.start,
            limit: this.chunkSize,
            search: this.search,
            sort: this.filterColumn,
            direction: this.filterDirection,
            extraFilters: this.extraFilters
        })
    }

    handleTableChange = (opts) => {
        if(this.loading === true) {
            return;
        }

        this.setState({data: []});

        this.loading = true;

        this.loadTimeout = setTimeout(() => {
            this.props.onTableChange(opts);
        }, 500);
    }

    handleSearchChange = (value) => {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
            this.stale = true;
            this.search = value;
            this.start = 0;

            this.handleTableChange({
                start: 0,
                limit: this.chunkSize,
                search: this.search,
                sort: this.filterColumn,
                direction: this.filterDirection,
                extraFilters: this.extraFilters
            })

        }, 200)
    }

    handleExtraFiltersChange = (column, value) => {
        this.stale = true;
        this.tableData = [];
        this.start = 0;
        this.extraFilters[column] = value;

        this.handleTableChange({
            start: 0,
            limit: this.chunkSize,
            search: this.search,
            sort: this.filterColumn,
            direction: this.filterDirection,
            extraFilters: this.extraFilters
        })
    }

    handleFilterChange = (column, direction) => {
        this.stale = true;
        this.start = 0;
        this.filterColumn = column;
        this.filterDirection = direction || this.filterDirection;

        this.handleTableChange({
            start: 0,
            limit: this.chunkSize,
            search: this.search,
            sort: this.filterColumn,
            direction: this.filterDirection,
            extraFilters: this.extraFilters
        })
    }

    handlePageChange(page) {
        this.handleTableChange({
            start: (page - 1) * this.chunkSize,
            limit: this.chunkSize,
            search: this.search,
            sort: this.filterColumn,
            direction: this.filterDirection,
            extraFilters: this.extraFilters
        })
    }

    componentDidUpdate() {
        if(this.oldKey !== this.props.updateKey) {
            this.stale = true;
            this.oldKey = this.props.updateKey;
        }

        this.updateTable();
    }

    updateTable = () => {
        const tableData = (this.props.data || []).map(item => {
            return this.props.columns.map(column => {
                if(column.decorator && typeof column.decorator === 'function') {
                    return column.decorator(item);
                }

                if(column.decorator && column.decorator === 'date') {
                    return this.dateDecorator(item[column.id]);
                }

                if(column.decorator && column.decorator === 'datetime') {
                    return this.datetimeDecorator(item[column.id]);
                }

                if(column.decorator && column.decorator === 'boolean') {
                    return !!item[column.id] ? 'Da' : 'Nu'
                }

                if(column.decorator && column.decorator === 'map') {
                    return this.mapDecorator(item[column.id], column.map)
                }

                return item[column.id];
            })
        })

        if(this.start === 0 && this.stale === true) {
            this.stale = false;
            this.loading = false;
            this.setState({
                data: tableData,
            });
        } else if(this.stale === true) {
            this.stale = false;
            this.setState({
                data: [...this.state.data, ...tableData],
            });
            this.loading = false;
        }
    }

    mapDecorator = (object, map) => {
        return map[object];
    }

    dateDecorator = (object) => {
         return moment(object).format('D MMM \'YY');
    }

    datetimeDecorator = (object) => {
        return object.format('D MMM \'YY HH:mm');
    }

    renderRowMenu = (item) => {
        return (
            this.props.itemMenu && this.props.itemMenu.length > 0 ?
                <Menu>
                    <Menu.Group>
                        {this.props.itemMenu.map((menuEntry, index) => (
                            <Link to={menuEntry.getLink(item)} key={`datatable-menu-${index}-${menuEntry.label}`}>
                                <Menu.Item icon={menuEntry.icon } >{menuEntry.label}</Menu.Item>
                            </Link>
                        ))}
                    </Menu.Group>
                </Menu> : null
        )
    }

    renderTableHeaderCell = (column) => {
        return (
            <Table.TextHeaderCell key={column.id} flexBasis={column.flexBasis || ""}>
                <Popover    
                    position={Position.BOTTOM_LEFT}
                    content={({close}) => (
                        <Menu>
                            <Menu.OptionsGroup title="Ordoneaza"
                                options={[
                                    { label: 'Crescator', value: 'ASC' },
                                    { label: 'Descrescator', value: 'DESC'}
                                ]}
                                
                                selected={this.filterColumn === column.id ? this.filterDirection : null}

                                onChange={value => {
                                    this.handleFilterChange(column.filterId || column.id, value);
                                    close()
                                }}
                            />

                            {column.options ? <Menu.OptionsGroup title="Filtreaza"
                                options={column.options}
                                selected={this.extraFilters ? this.extraFilters[column.filterId] : ""}
                                onChange={value=> {
                                    this.handleExtraFiltersChange(column.filterId || column.id, value);
                                    close();
                                }}
                            /> : null}
                        </Menu>
                    )}
                >
                    {typeof this.props.filters !== 'undefined' && this.props.filters === false ? <>{column.label}</> :
                    <TextDropdownButton 
                        icon={this.filterColumn === column.id ? 
                                (this.filterDirection.toLowerCase() === 'asc' ? <CaretUpIcon/>: 
                                this.filterDirection.toLowerCase() === 'desc' ? <CaretDownIcon/> : 
                                '') : ''}>
                        {column.label}
                        {this.extraFilters && this.extraFilters[column.filterId] ? <FilterIcon color="#3366FF" marginLeft={8}/> : null}
                    </TextDropdownButton>}
                </Popover>
            </Table.TextHeaderCell>
        )
    }

    render() {
        return (<>
            <Pagination page={this.props.page} totalPages={this.props.pageCount}
                onNextPage={() => { this.handlePageChange(this.page + 1) }}
                onPreviousPage={() => { this.handlePageChange(this.page - 1)}}
                onPageChange={page => { this.handlePageChange(page) }}
            />
            <Table border className="hover-table">
                {typeof this.props.search !== 'undefined' && this.props.search === false ? null : 
                <Table.Head>
                    <Table.SearchHeaderCell placeholder={this.search || 'Cauta dupa nume'} onChange={this.handleSearchChange}/>
                </Table.Head>}
                <Table.Head>
                    {this.props.itemMenu ? 
                        <Table.HeaderCell width={48} flex="none"/> : null }
                    {this.props.columns.filter(item => item.visible !== false).map(column => (
                        column.show !== false ? 
                            this.renderTableHeaderCell(column) : null
                    ))}
                </Table.Head>
                <Table.Body height={this.props.height ? this.props.height : 'calc(100vh - 350px)'}>
                    {this.state.data.map((item, index) => (
                        <Table.Row  className="hover-row" key={index}>
                            {this.props.itemMenu ?
                            <Table.Cell  className="hover-cell" width={48} flex="none">
                                <Popover
                                    content={this.renderRowMenu(item)}
                                    position={Position.BOTTOM_LEFT}>
                                    <IconButton icon={MoreIcon} height={24} appearance="minimal"/>
                                </Popover>
                            </Table.Cell> : null}
                            {item.filter((element, index) => this.props.columns[index].visible !== false).map((cell, cellIndex) => (
                                this.props.columns[cellIndex].show !== false ? 
                                    <Table.TextCell flexBasis={this.props.columns[cellIndex].flexBasis || null} className="hover-cell"
                                    title={cell ? cell.toString() : "N.A"} key={index+cellIndex}>{cell}</Table.TextCell> : null
                            ))}
                        </Table.Row>   
                    ))}
                </Table.Body>
            </Table>
            </>
        )
    }
}