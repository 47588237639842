import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getProduct, deleteProduct } from '../actions/ProductActions';
import { Pane, Heading, Button, WarningSignIcon } from 'evergreen-ui';

import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { Link } from 'react-router-dom';
import ProductInfoBlock from '../components/ProductInfoBlock';

class ViewProduct extends Component {
    constructor(props) {
        super(props);

        this.id = this.props.params.id;
    }
    
    async componentDidMount() {
        await this.props.dispatch(getProduct(this.id))
    }

    handleDelete = () => {
        this.props.dispatch(deleteProduct(this.props.product.id));
    }

    render() {
        if(_.isEmpty(this.props.product)) { 
            return <></>
        }
        return (
            <Pane padding={16} paddingTop={0}>
                <Pane display="flex">
                    <Pane>
                        <Link to="/products">
                            <Button iconBefore="arrow-left">
                                Inapoi la lista produse
                            </Button>
                        </Link>
                    </Pane>
                    
                    <Pane marginLeft={16} alignSelf="flex-end">
                        <DeleteDialog
                            onDelete={this.handleDelete}
                            buttonLabel="Sterge produsul"
                            title="Confirmare stergere produs"
                            confirmLabel="Sterg definitiv produsul"
                            message="Esti sigur ca vrei sa stergi produsul?"/>
                    </Pane>
                </Pane>

                <Pane flex={1} background="tint1" elevation={1} padding={16} marginTop={16}>
                    <Heading size={600}>
                        Produs - <strong>{this.props.product.title}</strong> 
                        {this.props.product.notifications.length > 0 ? <WarningSignIcon color="warning" marginRight={16}/> : null}
                    </Heading>
                </Pane>
                
                <Pane display="flex" justifyContent="space-between" flex={1} marginTop={16}>
                    <ProductInfoBlock product={this.props.product}/>
                </Pane>
            </Pane>
        )
    }
}

function mapStateToProps(state) {
    return {
        product: state.products.current
    }
}

export default connect(mapStateToProps)(ViewProduct);